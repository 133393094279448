.btn {
  padding: 0.375rem 0.75rem;
}

.link {
  text-transform: unset;
  font-size: 1rem;
  font-weight: 400;
}

.container {
  display: flex;
}

.app_bar {
  background-color: transparent;
  justify-content: center;
  box-shadow: none;
}

.logo_wrap {
  flex-grow: 1;
  align-items: center;
}

.logo_link {
  display: flex;
}

.links_wrap {
  flex-direction: row;
  align-items: center;
}

/*** Media Queries ***/

/* xs */
@media screen and (min-width: 0px) {
  .btn_wrap,
  .nav_wrap {
    padding: 0 0.5rem;
  }

  .app_bar {
    height: 3.25rem;
  }

  .logo_wrap {
    display: none;
  }

  .links_wrap {
    display: none;
  }
}

/* sm */
@media screen and (min-width: 600px) {
  .btn_wrap,
  .nav_wrap {
    padding: 0 1rem;
  }

  .app_bar {
    height: 7.25rem;
    padding: 0 2.4rem;
  }

  .logo_wrap {
    display: block;
  }

  .links_wrap {
    display: flex;
  }
}

/* md */
@media screen and (min-width: 900px) {
}

/* lg */
@media screen and (min-width: 1200px) {
}
