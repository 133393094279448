.container {
  text-align: center;
  padding: 0.25rem;
}

.link {
  text-transform: unset;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.link:hover {
  opacity: 1;
}

.social_wrap {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.social_wrap:hover {
  opacity: 1;
}

.social_img,
.logo_img {
  cursor: pointer;
}

.footer_container {
  height: 22.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo_wrap {
  margin-top: 3rem;
  display: flex;
}

.links_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
}

.links_wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.187rem;
}

.socials_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.65rem;
  gap: 1rem;
}

.copyright {
  font-size: 0.8rem;
  text-align: center;
  color: var(--text-main-color);
  padding-bottom: 0.5rem;
  line-height: 0.8rem;
}

/*** Media Queries ***/

/* xs */
@media screen and (min-width: 0px) {
  .container {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* sm */
@media screen and (min-width: 600px) {
  .container {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

/* md */
@media screen and (min-width: 900px) {
}

/* lg */
@media screen and (min-width: 1200px) {
}
