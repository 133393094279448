/* cyrillic-ext */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/f333cc3479ed4d1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/d058ce612f85345d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/b90ec1d97f848a2c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/c3008883a8bdda3d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/8da2e0e72f0432cc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Comfortaa_42be05';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/7a7c1dbbf88cd759-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Comfortaa_Fallback_42be05';src: local("Arial");ascent-override: 74.21%;descent-override: 19.71%;line-gap-override: 0.00%;size-adjust: 118.71%
}.__className_42be05 {font-family: '__Comfortaa_42be05', '__Comfortaa_Fallback_42be05', cursive, sans-serif;font-style: normal
}

.header_btn__Y98m6 {
  padding: 0.375rem 0.75rem;
}

.header_link__fiY0A {
  text-transform: unset;
  font-size: 1rem;
  font-weight: 400;
}

.header_container__xYHxC {
  display: flex;
}

.header_app_bar__4iOaY {
  background-color: transparent;
  justify-content: center;
  box-shadow: none;
}

.header_logo_wrap__goGLO {
  flex-grow: 1;
  align-items: center;
}

.header_logo_link__3aB8d {
  display: flex;
}

.header_links_wrap__e4ME8 {
  flex-direction: row;
  align-items: center;
}

/*** Media Queries ***/

/* xs */
@media screen and (min-width: 0px) {
  .header_btn_wrap__JuTV_,
  .header_nav_wrap__dgT1T {
    padding: 0 0.5rem;
  }

  .header_app_bar__4iOaY {
    height: 3.25rem;
  }

  .header_logo_wrap__goGLO {
    display: none;
  }

  .header_links_wrap__e4ME8 {
    display: none;
  }
}

/* sm */
@media screen and (min-width: 600px) {
  .header_btn_wrap__JuTV_,
  .header_nav_wrap__dgT1T {
    padding: 0 1rem;
  }

  .header_app_bar__4iOaY {
    height: 7.25rem;
    padding: 0 2.4rem;
  }

  .header_logo_wrap__goGLO {
    display: block;
  }

  .header_links_wrap__e4ME8 {
    display: flex;
  }
}

/* md */
@media screen and (min-width: 900px) {
}

/* lg */
@media screen and (min-width: 1200px) {
}

.footer_container__LsEE4 {
  text-align: center;
  padding: 0.25rem;
}

.footer_link__OpqiO {
  text-transform: unset;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.footer_link__OpqiO:hover {
  opacity: 1;
}

.footer_social_wrap__TWwjw {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.footer_social_wrap__TWwjw:hover {
  opacity: 1;
}

.footer_social_img__Vd__C,
.footer_logo_img__wNmuR {
  cursor: pointer;
}

.footer_footer_container__n5bij {
  height: 22.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer_logo_wrap__Eap4X {
  margin-top: 3rem;
  display: flex;
}

.footer_links_container__AG8BE {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
}

.footer_links_wrap__a3vVq {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.187rem;
}

.footer_socials_container__j8VIw {
  display: flex;
  align-items: center;
  margin-bottom: 0.65rem;
  gap: 1rem;
}

.footer_copyright__nFXY7 {
  font-size: 0.8rem;
  text-align: center;
  color: var(--text-main-color);
  padding-bottom: 0.5rem;
  line-height: 0.8rem;
}

/*** Media Queries ***/

/* xs */
@media screen and (min-width: 0px) {
  .footer_container__LsEE4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* sm */
@media screen and (min-width: 600px) {
  .footer_container__LsEE4 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

/* md */
@media screen and (min-width: 900px) {
}

/* lg */
@media screen and (min-width: 1200px) {
}

